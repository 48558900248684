import Vue from "vue";
import VueRouter from "vue-router";

// 首页和我们页
import indexView from "../views/indexView.vue";
import aboutUs from "../views/aboutUs.vue";

// 合作案例
import cooperate from "../views/cooperate.vue";
// import caseReovery from "../views/caseReovery.vue";

// 产品中心
import productLsz from "../views/productLsz.vue";
import productMk from "../views/productMk.vue";

Vue.use(VueRouter);

// 重写push函数, 解决多次点击异常
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: "/",
    redirect: "/indexView",
    component: indexView,
  },
  {
    path: "/indexView",
    name: "indexView",
    component: indexView,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/aboutUs.vue"),
  },
  {
    path: "/cooperate",
    name: "cooperate",
    component: cooperate,
  },
  // {
  //   path: "/caseReovery",
  //   name: "caseReovery",
  //   component: caseReovery,
  // },
  {
    path: "/productMk",
    name: "productMk",
    component: productMk,
  },
  {
    path: "/productLsz",
    name: "productLsz",
    component: productLsz,
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

export default router;
