<template>
  <div class="bannerCom">
    <div class="banner-box">
      <!-- 背景图 -->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="banner">
              <!-- 图片 -->
              <img
                class="box-img"
                src="../assets/img/indexView/banner-img.png"
                alt=""
              />
              <!-- 标题内容 -->
              <div class="banner-box content">
                <div class="text">
                  <p class="title">科技有道，来思智造</p>
                  <p>
                    科技改变生活，服务市场，诚信待人，追求完美，产品及人品的企业理念。在未来创新求实的道路上不断进行技术创新以及服务创新。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "navCom",
  data() {
    return {};
  },
  mounted() {
    // new Swiper(".swiper-container", {
    //   loop: false,
    //   // 如果需要自动切换海报
    //   autoplay: 5000,
    // });
  },
};
</script>

<style scoped lang="less">
.bannerCom {
  position: relative;
  width: 100%;
  // 1. banner轮播部分
  .swiper-container {
    width: 100%;
    // height: calc(100vw * 530 / 1920);
    height: 530px;
    // min-height: 530px;
    min-height: 430px;
    .swiper-wrapper {
      width: 100%;
      // height: calc(100vw * 530 / 1920);
      height: 530px;
      // min-height: 530px;

      .swiper-slide {
        width: 100%;
        // height: calc(100vw * 530 / 1920);
        // min-height: 530px;
        height: 530px;

        .banner {
          position: relative;
          width: 100%;
          // height: calc(100vw * 530 / 1920);
          height: 530px;
          // min-height: 530px;
          // min-height: 360px;
          // background-image: url("../assets/img/indexView/banner-img.png");
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          .box-img {
            position: absolute;
            width: 1920px;
            left: 50%;
            margin-left: -960px; /*图片宽度的一半*/
          }
          .banner-box {
            position: absolute;
            // top: 197px;
            bottom: 35.28%;
            left: 50%;
            transform: translateX(-50%);
            // 内容部分
            .text {
              .title {
                width: 486px;
                line-height: 63px;
                letter-spacing: 3px; // 设置对象中的文字之间的间隔
                font-size: 50px;
                font-weight: 500;
                font-family: Noto Sans S Chinese-Medium, Noto Sans S Chinese;
                color: #080816;
              }
              p {
                width: 486px;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0px;
                color: #606060;
                font-weight: 400;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
