<template>
  <div class="cooperate">
    <!-- 1. 头部导航 -->
    <navComTwo></navComTwo>
    <!-- 2. banner图部分 -->
    <div class="bannerCom">
      <div class="banner-box">
        <!-- 背景图 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="banner">
                <!-- 图片 -->
                <img
                  class="box-img"
                  src="../assets/img/cooperateView/cooperate-banner.png"
                  alt=""
                />
                <!-- 标题内容 -->
                <div class="banner-box content">
                  <div class="text">
                    <p class="title">至诚至信，合作双赢</p>
                    <p>
                      量身打造独具个性的企业数字生态形象深谙用户心智，共赴品牌之道
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3. 解决方案 -->
    <div class="cooperate-wrap">
      <div class="cooperate content">
        <!-- 标题 -->
        <div class="cooperate-title">
          <h1>来思科技为各行各业提供营销解决方案</h1>
        </div>
        <!-- 内容 -->
        <div class="cooperate-list">
          <div class="list-l">
            <div
              :class="['btn', item.activeBtn == true ? 'activeBtn' : '']"
              v-for="(item, index) in solveArr"
              :key="index"
              @click="btnFn(index)"
            >
              {{ item.btnName }}
            </div>
          </div>
          <div class="list-r">
            <ul>
              <template v-if="btnIndex == 0">
                <li v-for="(item, index) in solveList1" :key="index">
                  <img :src="item.imgSrc" alt="" />
                  <h5>{{ item.title }}</h5>
                  <p>
                    {{ item.text1 }}<br />
                    {{ item.text2 }}
                  </p>
                </li>
              </template>
              <template v-else-if="btnIndex == 1">
                <li v-for="(item, index) in solveList2" :key="index">
                  <img :src="item.imgSrc" alt="" />
                  <h5>{{ item.title }}</h5>
                  <p>
                    {{ item.text1 }}<br />
                    {{ item.text2 }}
                  </p>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 4. 合作案例 - 先注释 暂时没有合作详情页 -->
    <!-- <caseCom></caseCom> -->
    <!-- 5. 优势 -->
    <div class="advantage-wrap">
      <div class="advantage content">
        <!-- 标题 -->
        <div class="advantage-title">
          <h1>我们的优势</h1>
        </div>
        <!-- 内容 -->
        <div class="advantage-list">
          <img src="../assets/img/cooperateView/advantage-bg.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 6. 流程 -->
    <div class="flowpath-wrap">
      <div class="flowpath content">
        <!-- 标题 -->
        <div class="flowpath-title">
          <h1>合作流程</h1>
        </div>
        <!-- 内容 -->
        <div class="flowpath-list">
          <div class="list-item">
            <div class="item">
              <img src="../assets/img/cooperateView/flowpath-img.png" alt="" />
              <h5>项目咨询</h5>
              <p>商务洽谈，明确需求</p>
            </div>
          </div>
          <div class="next-img">
            <img src="../assets/img/cooperateView/next-img.png" alt="" />
          </div>
          <div class="list-item">
            <div class="item">
              <img src="../assets/img/cooperateView/flowpath-img2.png" alt="" />
              <h5>方案输出</h5>
              <p>提供定制化解决方案</p>
            </div>
          </div>
          <div class="next-img">
            <img src="../assets/img/cooperateView/next-img.png" alt="" />
          </div>
          <div class="list-item">
            <div class="item">
              <img src="../assets/img/cooperateView/flowpath-img3.png" alt="" />
              <h5>合同签约</h5>
              <p>达成合作意向并签约</p>
            </div>
          </div>
          <div class="next-img">
            <img src="../assets/img/cooperateView/next-img.png" alt="" />
          </div>
          <div class="list-item">
            <div class="item">
              <img src="../assets/img/cooperateView/flowpath-img4.png" alt="" />
              <h5>项目执行</h5>
              <p>按计划执行，全程跟进</p>
            </div>
          </div>
          <div class="next-img">
            <img src="../assets/img/cooperateView/next-img.png" alt="" />
          </div>
          <div class="list-item">
            <div class="item">
              <img src="../assets/img/cooperateView/flowpath-img5.png" alt="" />
              <h5>交付验收</h5>
              <p>交付报告，评估效果</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7. 联系我们 -->
    <!-- <contact-com></contact-com> -->
    <!-- 底部组件部分 -->
    <footerCom></footerCom>
  </div>
</template>

<script>
import navComTwo from "@/components/navComTwo.vue";
// import caseCom from "@/components/caseCom.vue";
// import contactCom from "@/components/contactCom.vue";
import footerCom from "@/components/footerCom.vue";
export default {
  data() {
    return {
      // 点击按钮下标
      btnIndex: 0,
      // 解决方案按钮数据
      solveArr: [
        {
          id: 1,
          btnName: "回收解决方案",
          activeBtn: true,
        },
        {
          id: 2,
          btnName: "租赁解决方案",
          activeBtn: false,
        },
      ],
      solveList1: [
        {
          id: 1,
          imgSrc: require("../assets/img/cooperateView/pro-img.png"),
          title: "环保/公益整合传播方案",
          text1: "发动创意力量 推动品牌声浪",
          text2: "全案创意策划执行，创新环保/公益体验活动",
        },
        {
          id: 2,
          imgSrc: require("../assets/img/cooperateView/pro-img2.png"),
          title: "品牌联合公益项目",
          text1: "200+公益项目 助推品牌出圈",
          text2: "成熟项目联合传播，权威公益机构背书",
        },
        {
          id: 3,
          imgSrc: require("../assets/img/cooperateView/pro-img3.png"),
          title: "公益资源合作支持",
          text1: "整合优势资源 赋能企业CSR",
          text2: "优势宣传曝光渠道，联动公益组织/全国高校",
        },
        {
          id: 4,
          imgSrc: require("../assets/img/cooperateView/pro-img4.png"),
          title: "环保回收物流支持",
          text1: "回收覆盖全国 精准数据统计",
          text2: "开通企业回收渠道，线上线下高效导流",
        },
        {
          id: 5,
          imgSrc: require("../assets/img/cooperateView/pro-img5.png"),
          title: "平台开发技术支持",
          text1: "强大技术团队 致力打磨精品",
          text2: "小程序技术支持，UI设计开发一体化",
        },
      ],
      solveList2: [
        {
          id: 1,
          imgSrc: require("../assets/img/cooperateView/pro2-img.png"),
          title: "平台化服务",
          text1: "对接小程序运营及芝麻信用",
          text2: "运营陪跑，协助提升",
        },
        {
          id: 2,
          imgSrc: require("../assets/img/cooperateView/pro2-img2.png"),
          title: "企业数字化转型",
          text1: "打好数字化转型战",
          text2: "助力中小企业“以租代买”",
        },
        {
          id: 3,
          imgSrc: require("../assets/img/cooperateView/pro2-img3.png"),
          title: "供应链优势",
          text1: "拥有强有力的产品采购支持",
          text2: "持续保持价格优势",
        },
        {
          id: 4,
          imgSrc: require("../assets/img/cooperateView/pro2-img4.png"),
          title: "全方位多重保障",
          text1: "风控免押金",
          text2: "司法仲裁，区块链及保险",
        },
        {
          id: 5,
          imgSrc: require("../assets/img/cooperateView/pro2-img4.png"),
          title: "平台开发技术支持",
          text1: "强大技术团队 致力打磨精品",
          text2: "小程序技术支持，UI设计开发一体化",
        },
      ],
    };
  },
  methods: {
    btnFn(i) {
      this.solveArr.forEach((item, index) => {
        item.activeBtn = false;
        if (index == i) {
          item.activeBtn = true;
          this.btnIndex = i;
        }
      });
    },
  },
  components: {
    navComTwo,
    // caseCom,
    // contactCom,
    footerCom,
  },
};
</script>

<style scoped lang="less">
// 摩咖banner图
.bannerCom {
  position: relative;
  width: 100%;
  // 1. banner轮播部分
  .swiper-container {
    width: 100%;
    // height: calc(100vw * 530 / 1920);
    height: 530px;
    // min-height: 530px;
    // min-height: 430px;
    .swiper-wrapper {
      width: 100%;
      // height: calc(100vw * 530 / 1920);
      height: 530px;
      // min-height: 530px;
      // min-height: 430px;

      .swiper-slide {
        width: 100%;
        // height: calc(100vw * 530 / 1920);
        // min-height: 530px;
        height: 530px;
        // min-height: 430px;

        .banner {
          position: relative;
          width: 100%;
          height: 530px;
          // height: calc(100vw * 530 / 1920);
          // min-height: 530px;
          // min-height: 360px;
          // background-image: url("../assets/img/cooperateView/cooperate-banner.png");
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          .box-img {
            position: absolute;
            width: 1920px;
            left: 50%;
            margin-left: -960px;
          }

          .banner-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // 内容部分
            .text {
              text-align: center;
              .title {
                line-height: 63px;
                letter-spacing: 3px; // 设置对象中的文字之间的间隔
                font-size: 50px;
                font-weight: 500;
                font-family: Noto Sans S Chinese-Medium, Noto Sans S Chinese;
                color: #ffffff;
              }
              p {
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0px;
                color: #dbdbdb;
                font-weight: 400;
                margin-top: 10px;
              }
            }
            // .code {
            //   width: 112px;
            //   text-align: center;
            //   .code-img {
            //     margin: 0 auto;
            //     background-color: #d9d9d9;
            //     width: 104px;
            //     height: 104px;
            //   }
            //   p {
            //     font-size: 14px;
            //     color: #ffffff;
            //     font-weight: 500;
            //     margin-top: 5px;
            //   }
            // }
          }
        }
      }
    }
  }
}
// 解决方案
.cooperate-wrap {
  .cooperate {
    padding-top: 87px;
    padding-bottom: 80px;
    // 标题
    .cooperate-title {
      h1 {
        font-size: 48px;
        line-height: 48px;
        font-weight: 500;
        color: #040c27;
        text-align: center;
        margin-bottom: 89px;
      }
    }
    // 内容
    .cooperate-list {
      display: flex;
      .list-l {
        .btn {
          font-size: 26px;
          font-weight: 400;
          width: 320px;
          height: 144px;
          line-height: 144px;
          text-align: center;
          color: #ffffff;
          background: #dddddd;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:nth-child(2) {
            margin-top: 40px;
          }
        }
        .activeBtn {
          background: linear-gradient(270deg, #126ef8 0%, #02a9f0 100%);
          color: #ffffff;
          height: 348px;
          line-height: 348px;
        }
      }
      .list-r {
        flex-grow: 1;
        ul {
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          li {
            width: 29.62%;
            height: 256px;
            margin-left: 2.85%;
            padding: 20px 20px 32px;
            // opacity: 0;
            // transition: all 0.3s linear;
            img {
              width: 96px;
              height: 96px;
              display: block;
              margin: 0 auto;
            }
            h5 {
              text-align: center;
              font-size: 20px;
              line-height: 20px;
              color: #2b384b;
              font-weight: 500;
              margin-top: 28px;
              margin-bottom: 12px;
            }
            p {
              text-align: center;
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              color: #848484;
            }
          }
        }
      }
    }
  }
}
// 优势
.advantage-wrap {
  .advantage {
    padding-top: 87px;
    padding-bottom: 80px;
    // 标题
    .advantage-title {
      h1 {
        font-size: 48px;
        line-height: 48px;
        font-weight: 500;
        color: #040c27;
        text-align: center;
        margin-bottom: 89px;
      }
    }
    // 内容
    .advantage-list {
      img {
        width: 100%;
        height: 33.57%;
      }
    }
  }
}
// 流程
.flowpath-wrap {
  background: #fafcfe;
  .flowpath {
    padding-top: 87px;
    padding-bottom: 80px;
    // 标题
    .flowpath-title {
      h1 {
        font-size: 48px;
        line-height: 48px;
        font-weight: 500;
        color: #040c27;
        text-align: center;
        margin-bottom: 89px;
      }
    }
    // 内容
    .flowpath-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .list-item {
        display: flex;
        align-items: center;
        .item {
          img {
            width: 96px;
            height: 96px;
            display: block;
            margin: 0 auto;
          }
          h5 {
            font-size: 20px;
            color: #040c27;
            text-align: center;
            margin-top: 20px;
            font-weight: 500;
            margin-bottom: 12px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #5e6576;
          }
        }
      }
    }
  }
}
</style>
