<template>
  <div class="navCom">
    <div class="nav-box content">
      <!-- 1. 右侧logo和内容 -->
      <div class="nav-left">
        <!-- logo图 -->
        <div class="lx-logo">
          <img
            src="../assets/img/indexView/lx-logo.png"
            alt=""
            style="width: 184px; height: 76px"
          />
        </div>
        <!-- 内容 -->
        <div class="nav-content">
          <div
            class="nav-btn"
            v-for="(item, index) in navObj"
            :key="index"
            @click="btnFn(item, index)"
          >
            <div :class="[item.active == true ? 'nav-items' : 'navFont']">
              {{ item.title }}
              <!-- @click="btnFn(item, index)" -->
            </div>
            <!-- 展开箭头 -->
            <template v-if="index == '1'">
              <img
                v-show="arrShow"
                src="../assets/img/indexView/expand-icon-up.png"
                style="
                  width: 10px;
                  height: 10px;
                  display: block;
                  margin-left: 10px;
                "
              />
              <img
                v-show="!arrShow"
                src="../assets/img/indexView/expand-icon-down.png"
                style="
                  width: 10px;
                  height: 10px;
                  display: block;
                  margin-left: 10px;
                "
              />
            </template>
            <!-- 展开部分 -->
            <template v-if="index == '1'">
              <div class="arr-list" v-show="arrShow">
                <div class="list-item" @click="toPage(0)">
                  <div class="img-box">
                    <img src="../assets/img/indexView/lsz-icon.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>来思租</h3>
                    <p>一站式信用免押租赁</p>
                  </div>
                </div>
                <div class="list-item" @click="toPage(1)">
                  <div class="img-box">
                    <img src="../assets/img/indexView/mk-icon.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>摩咖</h3>
                    <p>一站式全品类在线数字回收</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 2. 右侧电话号码 -->
      <div class="nav-right">
        <div class="phone-img">
          <img src="../assets/img/indexView/phone-icon.png" alt="" />
        </div>
        <div class="phone-num">
          <span>17033022367</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navCom",
  data() {
    return {
      // 导航数据
      navObj: [
        { id: 1, url: "/indexView", title: "首页", active: true },
        { id: 2, url: "", title: "产品中心", active: false },
        { id: 3, url: "/cooperate", title: "合作案例", active: false },
        { id: 4, url: "/aboutUs", title: "关于我们", active: false },
      ],
      // 控制展开框是否展示
      arrShow: false,
      activeIndex: 0, // 激活的下标
    };
  },
  methods: {
    // 点击函数
    btnFn(v, i) {
      console.log("当前点击的===>", v);
      // console.log("我当前的地址下标===>", i);
      // 点击时把激活的下标存起来
      // localStorage.setItem("activeIndex", JSON.stringify(i));
      if (i == 1) {
        // this.navFn(i);
        this.arrShow = !this.arrShow;
      } else {
        this.arrShow = false;
        // this.navFn(i);
        this.$router.push(v.url);
        // this.$router.resolve(v.url);
        // let routeUrl = this.$router.resolve({
        //   path: v.url,
        //   query: { id: 96 },
        // });
        // window.open(routeUrl.href, "_blank");
      }
    },
    // 遍历一级导航元素设置激活状态
    navFn(i) {
      this.navObj.forEach((it, index) => {
        it.active = false;
        if (i == this.active) {
          it.active = true;
        }
      });
    },
    // 产品中心的跳转方法
    toPage(i) {
      if (i == 0) {
        this.$router.push("/productLsz");
      } else if (i == 1) {
        this.$router.push("/productMk");
      }
    },
  },
  created() {
    // console.log("当前的路由===》", this.$route.path);
    this.navObj.forEach((it, index) => {
      it.active = false;
      if (this.$route.path == it.url) {
        it.active = true;
      }
    });
  },
};
</script>

<style scoped lang="less">
.navCom {
  .nav-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    // min-width: 1200px;
    height: 76px;
    top: 0;
    z-index: 999;
    // 1. 左侧内容和logo
    .nav-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // logo
      // .lx-logo {
      // }
      // 内容
      .nav-content {
        display: flex;
        .nav-btn {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 8px;
          margin: 0 20px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: #656f92;
          // border: 1px solid red;

          // 未激活字体样式
          .navFont {
            color: #656f92;
          }
          // 激活字体样式
          .nav-items {
            color: #040c27;
          }

          // 产品中心的展开部分-下拉框
          .arr-list {
            position: absolute;
            top: 48px;
            background: #fff;
            padding: 37px 50px 37px 32px;
            width: 276px;
            height: 186px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // z-index: 99;
            .list-item {
              display: flex;
              .img-box {
                margin-right: 10px;
                img {
                  width: 38px;
                  height: 38px;
                  display: block;
                }
              }
              .text-box {
                h3 {
                  color: #252525;
                  font-size: 14px;
                  font-weight: 400;
                }
                p {
                  font-size: 12px;
                  color: #848484;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    // 2. 电话号码
    .nav-right {
      position: relative;
      .phone-img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        left: -17px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .phone-num {
        border: 1px solid #1375ff;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
        padding: 0 11px 0 15px;
        height: 28px;
        line-height: 28px;
        span {
          font-size: 16px;
          color: #1375ff;
        }
      }
    }
  }
}
</style>
