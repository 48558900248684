// 按需导入方式
import {
  Form,
  Button,
  Card,
  FormItem,
  Input,
  Table,
  TableColumn,
  Pagination,
  Message,
  MessageBox,
} from "element-ui";

import Vue from "vue";

Vue.use(Form);
Vue.use(Button);
Vue.use(Card);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
