<template>
  <div class="productMk">
    <!-- 1. 头部导航 -->
    <navComTwo></navComTwo>
    <!-- 2. banner图部分 -->
    <div class="bannerCom">
      <div class="banner-box">
        <!-- 背景图 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="banner">
                <!-- 图片 -->
                <img
                  class="box-img"
                  src="../assets/img/productMk/mk-banner.png"
                  alt=""
                />
                <!-- 标题内容 -->
                <div class="banner-box content">
                  <div class="text">
                    <p class="title">一站式全品类在线数字回收平台</p>
                    <p>
                      “摩咖旧衣服回收”支付宝线上回收小程序，是以环保&公益的方式实现低碳健康生活的平台。通过“互联网+回收”方式协助用户以低碳方式处理废旧物品。
                    </p>
                  </div>
                  <div class="code">
                    <div class="code-img">
                      <img
                        src="../assets/img/mk_code.jpg"
                        style="width: 100%; height: 100%; display: block"
                        alt=""
                      />
                    </div>
                    <p>扫码了解小程序</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3. 摩咖广告部分 -->
    <div class="mk-adv content">
      <div class="adv-item">
        <img src="../assets/img/productMk/mk-adv.png" alt="" />
        <h5>愿景</h5>
        <p>服务至上</p>
      </div>
      <div class="adv-item">
        <img src="../assets/img/productMk/mk-adv2.png" alt="" />
        <h5>理念</h5>
        <p>团队协作</p>
      </div>
      <div class="adv-item">
        <img src="../assets/img/productMk/mk-adv3.png" alt="" />
        <h5>宗旨</h5>
        <p>科技创新</p>
      </div>
    </div>
    <!-- 4. 摩卡链路 -->
    <div class="mklink-wrap">
      <div class="mklink content">
        <!-- 标题 -->
        <div class="mklink-title">
          <h1>摩咖链路</h1>
        </div>
        <!-- 列表 -->
        <div class="mklink-list">
          <img src="../assets/img/productMk/link-img.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 5. 摩咖广告部分 -->
    <div class="introduce-wrap">
      <div class="introduce content">
        <!-- 左侧介绍 -->
        <div class="left-introduce">
          <h1>摩咖旧物回收平台<br />"互联网+回收"模式</h1>
          <div class="intr-list">
            <div class="list-item">
              <div class="item-l"></div>
              <div class="item-r">
                <h5>效率高范围广</h5>
                <p>
                  手机一键预约，快递极速上门,全国350+城市免费上门回收
                  便捷快速且覆盖范围广。
                </p>
              </div>
            </div>
            <div class="list-item">
              <div class="item-l"></div>
              <div class="item-r">
                <h5>精准智能回收</h5>
                <p>
                  信息化管理，大数据留存，客户信息长久保留，交易不断精准有效，更加智能。
                </p>
              </div>
            </div>
            <div class="list-item">
              <div class="item-l"></div>
              <div class="item-r">
                <h5>规范透明回收</h5>
                <p>
                  诚信回收，称重"一站式服务"，价格透明公道，回收重量在小程序上一目了然，留存老客户，发掘新客户。
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧图片展示 -->
        <div class="right-img">
          <img src="../assets/img/productMk/mk-bg.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 6. 合作服务 -->
    <div class="cooperate-wrap">
      <div class="cooperate content">
        <!-- 标题 -->
        <div class="cooperate-title">
          <h1>合作服务</h1>
        </div>
        <!-- 列表 -->
        <div class="cooperate-list">
          <div class="list-item">
            <div class="item-content">
              <img src="../assets/img/productMk/cooperate-img.png" alt="" />
              <h5>上线服务</h5>
            </div>
            <div class="item-text">
              小程序搭建，目前所有功能和流程都已经完善，全部赋能给商家，让商家不走一点弯路。
            </div>
          </div>
          <div class="list-item">
            <div class="item-content">
              <img src="../assets/img/productMk/cooperate-img2.png" alt="" />
              <h5>后台管理</h5>
            </div>
            <div class="item-text">
              后台搭建，小程序页面可根据自己喜好自由装修，可根据页面模板，官方模板自行安排。后续上新功能，也会同步更新。
            </div>
          </div>
          <div class="list-item">
            <div class="item-content">
              <img src="../assets/img/productMk/cooperate-img3.png" alt="" />
              <h5>配置教学</h5>
            </div>
            <div class="item-text">
              支付宝端服务配置，小程序关键词，服务关键词，小程序直达，有点东西，公域联动，生活号+运营。
            </div>
          </div>
          <div class="list-item">
            <div class="item-content">
              <img src="../assets/img/productMk/cooperate-img4.png" alt="" />
              <h5>经验分享</h5>
            </div>
            <div class="item-text">
              摩咖运营的经验、端内外全渠道的操作积累、官方活动报名的优势、真实有效流量渠道的鉴别等，都能让商家在流量运营上获得更多赋能事半功倍。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <!-- <contact-com></contact-com> -->
    <!-- 底部组件部分 -->
    <footerCom></footerCom>
  </div>
</template>

<script>
import Swiper from "swiper";
import navComTwo from "@/components/navComTwo.vue";
// import contactCom from "@/components/contactCom.vue";
import footerCom from "@/components/footerCom.vue";
export default {
  components: {
    navComTwo,
    // contactCom,
    footerCom,
  },
  mounted() {
    // new Swiper(".swiper-container", {
    //   loop: false,
    //   // 如果需要自动切换海报
    //   autoplay: 5000,
    // });
  },
};
</script>

<style scoped lang="less">
// 摩咖banner图
.bannerCom {
  position: relative;
  width: 100%;
  // 1. banner轮播部分
  .swiper-container {
    width: 100%;
    // height: calc(100vw * 530 / 1920);
    height: 530px;
    // min-height: 530px;
    // min-height: 430px;
    .swiper-wrapper {
      width: 100%;
      // height: calc(100vw * 530 / 1920);
      height: 530px;
      // min-height: 530px;
      // min-height: 430px;

      .swiper-slide {
        width: 100%;
        // height: calc(100vw * 530 / 1920);
        // min-height: 530px;
        height: 530px;
        // min-height: 430px;

        .banner {
          position: relative;
          width: 100%;
          // height: calc(100vw * 530 / 1920);
          // min-height: 530px;
          // min-height: 360px;
          height: 530px;
          // background-image: url("../assets/img/productMk/mk-banner.png");
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          .box-img {
            position: absolute;
            width: 1920px;
            left: 50%;
            margin-left: -960px;
          }
          .banner-box {
            position: absolute;
            bottom: 8.301%;
            left: 50%;
            transform: translateX(-50%);
            // 内容部分
            .text {
              margin-bottom: 5.2%;
              .title {
                // width: 486px;
                width: 756px;
                line-height: 63px;
                letter-spacing: 3px; // 设置对象中的文字之间的间隔
                font-size: 50px;
                font-weight: 500;
                font-family: Noto Sans S Chinese-Medium, Noto Sans S Chinese;
                color: #ffffff;
              }
              p {
                // width: 486px;
                width: 612px;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0px;
                color: #dbdbdb;
                font-weight: 400;
                margin-top: 10px;
              }
            }
            .code {
              width: 112px;
              text-align: center;
              .code-img {
                margin: 0 auto;
                background-color: #d9d9d9;
                width: 104px;
                height: 104px;
              }
              p {
                font-size: 14px;
                color: #ffffff;
                font-weight: 500;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
// 摩咖广告
.mk-adv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 64px;
  .adv-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      height: 200px;
      display: block;
    }
    h5 {
      font-size: 20px;
      line-height: 28px;
      color: #2b384b;
      // font-weight: 500;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #848484;
    }
  }
}
// 摩咖链路
.mklink-wrap {
  background: #fafcfe;
  .mklink {
    padding-top: 80px;
    padding-bottom: 57px;
    .mklink-title {
      text-align: center;
      h1 {
        font-size: 48px;
        line-height: 48px;
        color: #040c27;
        font-weight: 500;
        margin-bottom: 89px;
      }
    }
    .mklink-list {
      // width: 1400px;
      // height: 840px;
      width: 100%;
      height: 60%;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
// 摩咖产品介绍
.introduce-wrap {
  .introduce {
    display: flex;
    align-items: center;
    .left-introduce {
      width: 680px;
      padding-top: 80px;
      padding-left: 35px;
      padding-right: 80px;
      .intr-list {
        margin-top: 50px;
        .list-item {
          display: flex;
          margin: 20px 0;
          .item-l {
            background-color: #126ef8;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-top: 8px;
          }
          .item-r {
            margin-left: 10px;
            width: 528px;
            h5 {
              color: #2b384b;
              font-size: 20px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #848484;
              line-height: 28px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .right-img {
      // width: 680px;
      // height: 656px;
      width: 48.57%;
      height: 46.85%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

// 合作服务
.cooperate-wrap {
  background: #fafcfe;
  .cooperate {
    padding: 79px 0 80px 0;
    .cooperate-title {
      text-align: center;
      h1 {
        font-size: 48px;
        line-height: 48px;
        color: #040c27;
        font-weight: 500;
        margin-bottom: 89px;
        margin-bottom: 143px;
      }
    }
    .cooperate-list {
      display: flex;
      justify-content: space-between;
      .list-item {
        // width: 320px;
        position: relative;
        width: 22.85%;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 4% 31px;
        .item-content {
          position: absolute;
          top: -64px;
          img {
            width: 128px;
            width: 128px;
            display: block;
          }
          h5 {
            text-align: center;
            line-height: 23px;
            font-size: 20px;
            font-weight: 400;
            color: #252525;
            margin-top: 10px;
          }
        }
        .item-text {
          font-size: 15px;
          margin-top: 140px;
          text-align: justify;
          line-height: 28px;
          color: #848484;
          padding-top: 32px;
          border-top: 1px solid #f3f3f3;
        }
      }
    }
  }
}
</style>
