<template>
  <div class="aboutUs">
    <!-- 1. 导航组件 -->
    <nav-com></nav-com>
    <!-- 2. banner图组件 -->
    <div class="bannerCom">
      <div class="banner-box">
        <!-- 背景图 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="banner">
                <!-- 图片 -->
                <img
                  class="box-img"
                  src="../assets/img/aboutView/culture-banner.png"
                  alt=""
                />
                <!-- 标题内容 -->
                <div class="banner-box content">
                  <div class="text">
                    <p class="title">科技创造生活<br />精诚赢得未来</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3. 公司介绍 -->
    <div class="main-wrap">
      <div class="introduction content">
        <div class="company">
          <!-- 左侧介绍 -->
          <div class="left-introduce">
            <h1>合肥来思信息科技有限责任公司</h1>
            <p>
              来思信息科技有限公司成立于2022年2月10日<br />
              公司专注于租赁产品和回收产品小程序的研究、开发、运营及销售的高科技企业，总部及研发基地设立于中国科创之城安徽省合肥市政务区。<br />
              回收项目：“摩咖旧衣服回收”是以环保&公益的方式实现低碳健康生活的平台，首次上线当月旧衣回收总量达10000kg。通过“互联网+回收”方式协助用户以低碳方式处理废旧物品，共同助力“十四五”节能减排目标，为实现碳达峰、碳中和发挥重要作用。摩咖环保回收闲置，助力二次循环，致力于让每一件旧物都发挥最大的价值。<br />
              租赁项目：“来思租”秉承“以租代购、先享后付、共享共赢”的信用消费新理念，服务年轻化、爱时尚、爱科技的优质用户群体，不断提升用户在高端智能科技产品方面的体验感和便利度，同时帮助用户降低新产品的使用成本，进一步在零售端助力国人消费模式升级，接轨当下新零售经济。<br />
              公司自成立以来，始终秉承“诚信、专业”的经营理念。并且集设计、研发、销售，坚持“内强素质、外树形象、质量至上、规范发展”的治企方针，发杨“求真务实，顽强拼搏”的企业作风，服务于广大企业。帮忙合作企业提高运营水平和管理，使企业在激烈的市场竞争中始终坚持竞争力，实现企业快速、稳定地发展。
            </p>
          </div>
          <!-- 右侧图片展示 -->
          <div class="right-img">
            <img src="../assets/img/aboutView/intri-img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 4. 企业文化 -->
    <div class="culture-wrap">
      <div class="culture content">
        <!-- 标题 -->
        <div class="culture-title">
          <h1>企业文化</h1>
          <p>引进人才，培养人才，尊重人才是来思科技创业之源，竞争之源</p>
        </div>
        <!-- 列表 -->
        <div class="culture-list">
          <div
            class="list-item"
            v-for="(item, index) in cultureList"
            :key="index"
          >
            <img :src="item.imgSrc" alt="" />
            <h5>{{ item.title }}</h5>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 5. 企业发展 -->
    <developCom></developCom>
    <!-- 6. 联系我们 -->
    <!-- <contact-com></contact-com> -->
    <!-- 底部组件部分 -->
    <footerCom></footerCom>
  </div>
</template>

<script>
import Swiper from "swiper";
// 引入导航组件
import navCom from "@/components/navCom.vue";
// import bannerCom from "@/components/bannerCom.vue";
// import contactCom from "@/components/contactCom.vue";
import footerCom from "@/components/footerCom.vue";
import developCom from "@/components/developCom.vue";
export default {
  data() {
    return {
      lineIndex: 1, // 左侧竖线的激活下标
      cultureList: [
        {
          id: 1,
          imgSrc: require("../assets/img/aboutView/culture-shimi.png"),
          title: "公司使命",
          text: "促进生活和商业进步",
        },
        {
          id: 2,
          imgSrc: require("../assets/img/aboutView/culture-yj.png"),
          title: "公司愿景",
          text: "以探索创新创造价值的企业",
        },
        {
          id: 3,
          imgSrc: require("../assets/img/aboutView/culture-jzg.png"),
          title: "价值观",
          text: "诚信、务实、创新、合作、共赢",
        },
        {
          id: 4,
          imgSrc: require("../assets/img/aboutView/culture-rc.png"),
          title: "人才观",
          text: "人品至上 能力优先，担当第一",
        },
      ],
    };
  },
  components: {
    navCom,
    // bannerCom,
    // contactCom,
    footerCom,
    developCom,
  },
  methods: {
    // tabs切换
    tabsFn(it, i) {
      this.lineIndex = i;
      this.tiemNav.forEach((item, index) => {
        item.activeIndex = false;
        if (i == index) {
          item.activeIndex = true;
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
// banner图
.bannerCom {
  position: relative;
  width: 100%;
  // 1. banner轮播部分
  .swiper-container {
    width: 100%;
    // height: calc(100vw * 530 / 1920);
    height: 530px;
    // min-height: 530px;
    min-height: 430px;
    .swiper-wrapper {
      width: 100%;
      // height: calc(100vw * 530 / 1920);
      height: 530px;
      // min-height: 530px;

      .swiper-slide {
        width: 100%;
        // height: calc(100vw * 530 / 1920);
        // min-height: 530px;
        height: 530px;

        .banner {
          position: relative;
          width: 100%;
          // height: calc(100vw * 530 / 1920);
          height: 530px;
          // min-height: 530px;
          // min-height: 360px;
          // background-image: url("../assets/img/indexView/banner-img.png");
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          .box-img {
            position: absolute;
            width: 1920px;
            left: 50%;
            margin-left: -960px; /*图片宽度的一半*/
          }
          .banner-box {
            position: absolute;
            // top: 197px;
            bottom: 35.28%;
            left: 50%;
            transform: translateX(-50%);
            // 内容部分
            .text {
              .title {
                width: 486px;
                line-height: 63px;
                letter-spacing: 3px; // 设置对象中的文字之间的间隔
                font-size: 50px;
                font-weight: 500;
                font-family: Noto Sans S Chinese-Medium, Noto Sans S Chinese;
                color: #080816;
              }
              p {
                width: 486px;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0px;
                color: #606060;
                font-weight: 400;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
// 公司介绍部分
.main-wrap {
  width: 100%;
  padding: 80px 0;
  background-color: #ffffff;

  .introduction {
    // 公司
    .company {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-introduce {
        // width: 750px;
        width: 57.14%;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 56px;
        padding-bottom: 50px;
        // border: 1px solid blue;
        h1 {
          font-size: 40px;
          font-weight: 400;
          line-height: 47px;
          color: #040c27;
        }
        p {
          font-size: 16px;
          color: #848484;
          font-weight: 400;
          line-height: 32px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          margin-top: 32px;
          // border: 1px solid orange;
        }
      }
      .right-img {
        width: 40%;
        height: 44.5%;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
// 企业文化部分
.culture-wrap {
  background: #fafcfe;
  width: 100%;
  .culture {
    padding: 56px 0 80px 0;
    .culture-title {
      text-align: center;
      h1 {
        font-size: 48px;
        font-weight: 500;
        color: #040c27;
        line-height: 48px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        color: #848484;
        margin-top: 22px;
        margin-bottom: 87px;
      }
    }
    .culture-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list-item {
        text-align: center;
        background: #fff;
        // padding: 62px 97px;
        // padding: 42px 77px;
        width: 22.85%; // 320 312
        padding: 4.42% 0;
        img {
          width: 88px;
          height: 88px;
          margin: 0 auto;
          display: block;
        }
        h5 {
          font-size: 20px;
          margin-top: 40px;
          margin-bottom: 14px;
          font-weight: 400;
          color: #252525;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #848484;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
