<template>
  <div class="indexView">
    <!-- 1. 导航组件 -->
    <nav-com></nav-com>
    <!-- 2. banner图组件 -->
    <banner-com></banner-com>
    <!-- 3. 主体内容部分 -->
    <div class="main">
      <!-- 广告图和公司介绍 -->
      <div class="main-wrap">
        <div class="main-box content">
          <!-- 3-1 广告图标部分 -->
          <div class="adv-wrap">
            <div class="adv-item">
              <img src="../assets/img/indexView/adv-img.png" alt="" />
              <p>服务至上</p>
            </div>
            <div class="adv-item">
              <img src="../assets/img/indexView/adv-img2.png" alt="" />
              <p>团队协作</p>
            </div>
            <div class="adv-item">
              <img src="../assets/img/indexView/adv-img3.png" alt="" />
              <p>科技创新</p>
            </div>
            <div class="adv-item">
              <img src="../assets/img/indexView/adv-img4.png" alt="" />
              <p>专注专业</p>
            </div>
          </div>
          <!-- 3-2 公司和产品介绍部分 -->
          <div class="introduction">
            <div class="company">
              <!-- 左侧介绍 -->
              <div class="left-introduce">
                <h1>合肥来思信息科技有限责任公司</h1>
                <p>
                  来思信息科技有限公司成立于2022年2月10日<br />
                  公司专注于租赁产品和回收产品小程序的研究、开发、运营及销售的高科技企业，总部及研发基地设立于中国科创之城安徽省合肥市政务区。<br />
                  公司自成立以来，始终秉承“诚信、专业”的经营理念。并且集设计、研发、销售，坚持“内强素质、外树形象、质量至上、规范发展”的治企方针，发杨“求真务实，顽强拼搏”的企业作风，服务于广大企业。帮忙合作企业提高运营水平和管理，使企业在激烈的市场竞争中始终坚持竞争力，实现企业快速、稳定地发展。
                </p>
                <div class="more-btn" @click="toPage('/aboutUs')">
                  <div class="text">了解更多</div>
                  <img
                    class="right-arrow"
                    src="../assets/img/indexView/right-arrow.png"
                  />
                </div>
              </div>
              <!-- 右侧图片展示 -->
              <div class="right-img">
                <img src="../assets/img/indexView/adv-max-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品介绍来思租 -->
      <div class="main-wrap2">
        <div class="main-box2 content">
          <div class="introduction">
            <div class="product-lx">
              <!-- 左侧图片展示 -->
              <div class="left-img">
                <img src="../assets/img/indexView/lsz-max-img.png" alt="" />
              </div>
              <!-- 右侧介绍 -->
              <div class="right-introduce">
                <div class="symbol" style="width: 80px; height: 80px">
                  <!-- 图片 -->
                  <img
                    src="../assets/img/indexView/symbol-icon.png"
                    style="width: 100%; height: 100%; display: block"
                  />
                </div>
                <div class="introduce">
                  <h1>来思租小程序</h1>
                  <p>
                    来思租是来思信息科技有限责任公司自主研发的小程序，和蚂蚁链、支付宝、芝麻信用等公司战略合作的一站式全品类信用免押租赁及数字化科技平台。
                    <br />
                    作为蚂蚁科技战略生态圈的成员之一，业务覆盖平台化服务、自营租赁业务以及数字科技赋能等领域。
                  </p>
                  <div class="more-btn" @click="toPage('/productLsz')">
                    <div class="text">了解更多</div>
                    <img
                      class="right-arrow"
                      src="../assets/img/indexView/right-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品介绍来摩咖-->
      <div class="main-wrap3">
        <div class="main-box3 content">
          <div class="introduction">
            <div class="product-mk">
              <!-- 右侧介绍 -->
              <div class="right-introduce">
                <div class="symbol" style="width: 80px; height: 80px">
                  <img
                    src="../assets/img/indexView/symbol-icon.png"
                    style="width: 100%; height: 100%; display: block"
                  />
                </div>
                <div class="introduce">
                  <h1>摩咖小程序</h1>
                  <p>
                    摩咖是来思信息科技有限责任公司自主研发的小程序，是以环保&公益的方式实现低碳健康生活的平台。
                    <br />
                    将传统回收走向场景数字化、信息化建设，实现简单回收，透明回馈，最大限度提升居民旧衣回收的积极性，助力实现低碳生活。
                  </p>
                  <div class="more-btn" @click="toPage('/productMk')">
                    <div class="text">了解更多</div>
                    <img
                      class="right-arrow"
                      src="../assets/img/indexView/right-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <!-- 左侧图片展示 -->
              <div class="left-img">
                <img src="../assets/img/indexView/mk-max-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 研发实力 -->
      <div class="main-wrap4">
        <div class="strength content">
          <!-- 标题 -->
          <div class="strength-title">
            <h1>强大的研发实力</h1>
            <p>让专业的人做专业的事，不必担心系统问题</p>
          </div>
          <!-- 列表 -->
          <div class="strength-list">
            <div
              class="list-item"
              v-for="(item, index) in strengthList"
              :key="index"
            >
              <img :src="item.imgSrc" alt="" />
              <h5>{{ item.title }}</h5>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 最新动态-暂时注释 目前没有动态页 -->
      <!-- <div class="main-wrap5">
        <div class="trends content">
          <div class="trends-title">
            <h1>来思科技最新动态</h1>
          </div>
          <div class="trends-list">
            <div
              class="list-item"
              v-for="(item, index) in trendsList"
              :key="index"
            >
              <div class="img-box">
                <img :src="item.imgSrc" alt="" />
              </div>
              <h5>{{ item.title }}</h5>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 4. 招聘岗位 -->
    <div class="post-wrap">
      <div class="post content">
        <div class="post-title">
          <h1>在招岗位</h1>
          <p>专业团队与高效办公环境，期待你的加入</p>
        </div>
        <div class="post-list">
          <table
            style="width: 100%"
            border="1"
            class="list-table"
            cellspacing="0"
          >
            <tr class="title">
              <th>职位名称</th>
              <th>职位类型</th>
              <th>工作年限</th>
              <th>工作地点</th>
              <th>岗位描述/要求</th>
            </tr>
            <tr
              class="lists"
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
            >
              <td>商务BD</td>
              <td>销售</td>
              <td>1年以上</td>
              <td>合肥·蜀山区</td>
              <td>维护客户关系，开发新客户</td>
            </tr>
            <tr class="lists noCotent">
              <td colspan="5" class="text-box">
                <p>暂无更多</p>
              </td>
            </tr>
            <!-- <tr>
              暂无更多
            </tr> -->
          </table>
        </div>
      </div>
    </div>
    <!-- 5. 联系我们 -->
    <!-- <contact-com></contact-com> -->
    <!-- 底部组件部分 -->
    <footerCom></footerCom>
    <!-- 在招岗位弹框 -->
    <div
      class="post-box"
      v-show="showBox"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <!-- 公司信息 -->
      <div class="company-inf">
        <p>
          <strong>商务BD</strong>
        </p>
        <p>合肥来思信息科技有限责任公司·HR·张经理</p>
        <p>联系电话：18856975845</p>
      </div>
      <!-- 岗位信息 -->
      <div class="post-inf">
        <div class="inf-list">
          <p>
            <strong>岗位职责：</strong>
          </p>
          <ol>
            <li>1. 全国范围内SAAS软件渠道销售；</li>
            <li>2. 通过电话，拜访等形式维护客户关系，开发新客户；</li>
            <li>3. 定期复盘，总结可复制的商务流程；</li>
          </ol>
        </div>
        <div class="inf-list">
          <p>
            <strong>任职要求；</strong>
          </p>
          <ol>
            <li>1. 大专及以上学历，年龄22-35周岁，可接受出差，有出差补贴；</li>
            <li>2. 一年及以上商务BD经验，软件，互联网行业优先；</li>
            <li>
              3.
              具有较强的沟通能力，执行能力，普通话清晰，认可公司产品，价值观正；
            </li>
            <li>
              4.
              积极外向，有自驱力，较好的客户对话能力，愿意接受旧物回收行业，有环保行业意愿度，学习新鲜事物；
            </li>
          </ol>
        </div>
        <div class="inf-list">
          <p>
            <strong>薪资待遇：</strong>
          </p>
          <ol>
            <li>1. 大小周，缴纳五险；</li>
            <li>2. 底薪4000+10%提成+佣金，出差补贴100元/天，话补；</li>
            <li>3. 入职体检，节假日福利，带薪年假，扁平化管理;</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入导航组件
import navCom from "@/components/navCom.vue";
// 引入导航组件
import bannerCom from "@/components/bannerCom.vue";
import footerCom from "@/components/footerCom.vue";
// import contactCom from "@/components/contactCom.vue";
export default {
  name: "indexView",
  data() {
    return {
      // 招聘岗位
      // 职位名称 职位类型 工作年限 工作地点 岗位描述/要求
      // name types life address describe
      // 商务BD 销售 1年以上 合肥·蜀山区 维护客户关系，开发新客户
      postData: [
        {
          name: "商务BD",
          types: "销售",
          life: "1年以上",
          address: "合肥·蜀山区",
          describe: "维护客户关系，开发新客户",
        },
      ],
      // 广告语数据
      strengthList: [
        {
          id: 1,
          imgSrc: require("../assets/img/indexView/adv2-img.png"),
          title: "安全稳定",
          text: "多年技术积累，全方位监控 为百万商户业务保驾护航",
        },
        {
          id: 2,
          imgSrc: require("../assets/img/indexView/adv2-img2.png"),
          title: "极致体验",
          text: "让您无论在身在全球何处  均能获得灵活流畅的极致体验",
        },
        {
          id: 3,
          imgSrc: require("../assets/img/indexView/adv2-img3.png"),
          title: "高效智能",
          text: "大数据和人工智能技术 为您提供BI报表、智能客服等",
        },
        {
          id: 4,
          imgSrc: require("../assets/img/indexView/adv2-img4.png"),
          title: "数据安全",
          text: "自主研发多级加密 保护您和用户的数据更加安全",
        },
      ],
      // 新闻动态数据
      trendsList: [
        {
          id: 1,
          imgSrc: require("../assets/img/indexView/adv-max-img.png"),
          title: "新闻标题",
          text: "这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个一个新闻内容这是一个新闻内容这是一个一个新闻内容这是一个新闻内容这是...",
        },
        {
          id: 2,
          imgSrc: require("../assets/img/indexView/adv-max-img.png"),
          title: "新闻标题",
          text: "这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个一个新闻内容这是一个新闻内容这是一个一个新闻内容这是一个新闻内容这是...",
        },
        {
          id: 3,
          imgSrc: require("../assets/img/indexView/adv-max-img.png"),
          title: "新闻标题",
          text: "这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个新闻内容这是一个一个新闻内容这是一个新闻内容这是一个一个新闻内容这是一个新闻内容这是...",
        },
      ],
      // 显示弹框
      showBox: false,
    };
  },
  components: {
    navCom,
    bannerCom,
    footerCom,
    // contactCom,
  },
  methods: {
    // 鼠标移入移除事件
    handleMouseEnter() {
      console.log("移入了");
      this.showBox = true;
    },
    handleMouseLeave() {
      console.log("移除了");
      this.showBox = false;
    },
    // 跳转
    toPage(url) {
      this.$router.push(url);
    },
    scrollBehavior(to, from, savedPosition) {
      // if判断可加可不加、根据自己需求
      // savedPosition当且仅当通过浏览器的前进/后退按钮触发时才可用
      if (savedPosition) {
        return savedPosition;
      }
      return {
        x: 0,
        y: 0,
      };
    },
  },
};
</script>

<style lang="less" scoped>
// 修改element-ui样式
// /deep/.el-table th.el-table__cell {
//   text-align: center;
// }
// /deep/.el-table .cell {
//   text-align: center;
// }

// 主体
.main {
  width: 100%;
  // 广告图和公司介绍
  .main-wrap {
    width: 100%;
    background-color: #ffffff;
    .main-box {
      // margin: 0 auto;
      // width: 1200px;
      padding-bottom: 50px;
      // 1. 广告图部分
      .adv-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 320px;
        margin-top: 56px;
        margin-bottom: 48px;
        .adv-item {
          img {
            width: 192px;
            height: 192px;
          }
          p {
            font-size: 20px;
            font-weight: bold;
            color: #000000;
            text-align: center;
            padding-top: 16px;
          }
        }
      }
      // 2. 公司和介绍部分
      .introduction {
        // 公司
        .company {
          display: flex;
          justify-content: space-between;
          .left-introduce {
            // width: 750px;
            width: 53.57%;
            padding-top: 56px;
            h1 {
              font-size: 40px;
              font-weight: 400;
              line-height: 47px;
              color: #040c27;
            }
            p {
              font-size: 16px;
              color: #848484;
              font-weight: 400;
              line-height: 32px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              margin: 32px 0 100px 0;
            }
            .more-btn {
              width: 172px;
              height: 54px;
              border-radius: 4px;
              background-color: #4187ff;
              display: flex;
              justify-content: space-around;
              align-items: center;
              padding: 0 32px;
              cursor: pointer;
              .text {
                color: #ffffff;
                font-size: 18px;
                font-weight: 400;
              }
              .right-arrow {
                width: 26px;
                height: 26px;
                display: block;
                margin-left: 10px;
                transition: all 0.4s; /* 从最初到鼠标悬停时的过渡 */
              }
              &:hover {
                .right-arrow {
                  transform: translateX(5px);
                }
              }
            }
          }
          .right-img {
            // width: 560px;
            // height: 560px;
            width: 40%;
            // width: 40%;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
  // 产品介绍来思租
  .main-wrap2 {
    width: 100%;
    background: #fafcfe;
    .main-box2 {
      // 2. 产品介绍部分
      .introduction {
        // 公司
        .product-lx {
          display: flex;
          justify-content: space-between;
          .right-introduce {
            // border: 1px solid red;
            // width: 750px;// 以1400为基准
            width: 53.57%;
            padding-top: 152px;
            display: flex;
            justify-content: space-between;
            .symbol {
              margin-top: -39px;
            }
            .introduce {
              flex: 1;
              h1 {
                font-size: 38px;
                font-weight: 400;
                line-height: 45px;
                color: #040c27;
              }
              p {
                font-size: 16px;
                color: #848484;
                font-weight: 400;
                line-height: 32px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                margin: 24px 0 91px 0;
              }
              .more-btn {
                width: 172px;
                height: 54px;
                border-radius: 4px;
                background-color: #4187ff;
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 0 32px;
                cursor: pointer;
                .text {
                  color: #ffffff;
                  font-size: 18px;
                  font-weight: 400;
                }
                .right-arrow {
                  width: 26px;
                  height: 26px;
                  display: block;
                  margin-left: 10px;
                  transition: all 0.4s; /* 从最初到鼠标悬停时的过渡 */
                }
                &:hover {
                  .right-arrow {
                    transform: translateX(5px);
                  }
                }
              }
            }
          }
          .left-img {
            width: 40%;
            img {
              width: 100%;
              height: 100%;
              // width: 560px;
              // height: 656px;
              display: block;
            }
          }
        }
      }
    }
  }
  // 产品介绍摩咖
  .main-wrap3 {
    width: 100%;
    background: #ffffff;
    .main-box3 {
      // margin: 0 auto;
      // width: 1200px;
      // 2. 产品介绍部分
      .introduction {
        // 公司
        .product-mk {
          display: flex;
          justify-content: space-between;
          .right-introduce {
            // width: 750px;
            width: 53.57%;
            padding-top: 152px;
            display: flex;
            justify-content: space-between;
            .symbol {
              margin-top: -39px;
            }
            .introduce {
              flex: 1;
              h1 {
                font-size: 38px;
                font-weight: 400;
                line-height: 45px;
                color: #040c27;
              }
              p {
                font-size: 16px;
                color: #848484;
                font-weight: 400;
                line-height: 32px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                margin: 24px 0 91px 0;
              }
              .more-btn {
                width: 172px;
                height: 54px;
                border-radius: 4px;
                background-color: #4187ff;
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 0 32px;
                cursor: pointer;
                .text {
                  color: #ffffff;
                  font-size: 18px;
                  font-weight: 400;
                }
                .right-arrow {
                  width: 26px;
                  height: 26px;
                  display: block;
                  margin-left: 10px;
                  transition: all 0.4s; /* 从最初到鼠标悬停时的过渡 */
                }
                &:hover {
                  .right-arrow {
                    transform: translateX(5px);
                  }
                }
              }
            }
          }
          .left-img {
            width: 40%;
            img {
              width: 100%;
              height: 100%;
              // width: 560px;
              // height: 656px;
              display: block;
            }
          }
        }
      }
    }
  }
  // 研发实力
  .main-wrap4 {
    background: #fafcfe;
    .strength {
      padding: 80px 0;
      // 标题
      .strength-title {
        h1 {
          font-size: 48px;
          font-weight: 500;
          color: #040c27;
          line-height: 48px;
          text-align: center;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          color: #848484;
          text-align: center;
          margin-top: 22px;
          margin-bottom: 89px;
        }
      }
      // 列表
      .strength-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list-item {
          background: #fff;
          text-align: center;
          width: 24%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 56px 60px;
          img {
            width: 104px;
            height: 104px;
            display: block;
          }
          h5 {
            font-size: 20px;
            line-height: 32px;
            color: #252525;
            font-weight: 400;
            margin-top: 52px;
            margin-bottom: 19px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #848484;
            line-height: 28px;
          }
        }
      }
    }
  }
  // 最新动态
  .main-wrap5 {
    .trends {
      background: #fff;
      padding: 80px 0;
      // 标题
      .trends-title {
        h1 {
          font-size: 48px;
          font-weight: 500;
          color: #040c27;
          line-height: 48px;
          text-align: center;
          margin-bottom: 90px;
        }
      }
      // 列表
      .trends-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list-item {
          background: #fff;
          text-align: center;
          width: 31.42%;
          display: flex;
          flex-direction: column;
          align-items: center;
          // padding: 56px 60px;
          padding-bottom: 32px;
          .img-box {
            // height: 325px;
            width: 100%;
            background: pink;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          h5 {
            font-size: 20px;
            line-height: 28px;
            color: #252525;
            font-weight: 400;
            margin-top: 28px;
            margin-bottom: 16px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: #848484;
            line-height: 26px;
            text-align: left;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
}

// 在招岗位
.post-wrap {
  background: #ffffff;
  width: 100%;
  .post {
    padding: 56px 0 80px 0;
    // 标题
    .post-title {
      text-align: center;
      // border: 1px solid red;
      h1 {
        font-size: 48px;
        font-weight: 500;
        color: #040c27;
        line-height: 48px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        color: #848484;
        margin-top: 22px;
        margin-bottom: 87px;
      }
    }
    // 内容-表格
    .post-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 表格
      background: #f5faff;
      border-radius: 10px;
      overflow: hidden;
      .list-table {
        text-align: center;
        border: none;
        // 标题部分
        .title {
          height: 80px;
          background: #a9c8ff;
          border: none;
          th {
            border: none;
            font-size: 18px;
            color: #fff;
            font-weight: 500;
            width: 20%;
          }
        }

        // 数据部分
        .lists {
          border-bottom: #e4e4e4;
          cursor: pointer;
          td {
            color: #848484;
            font-size: 16px;
            font-weight: 500;
            height: 86px;
            border-top: none;
            border-left: none;
            border-right: none;
            &:nth-child(1) {
              color: #252525;
            }
          }
        }
        // 暂无更多
        .noCotent {
          border-bottom: transparent;
          text-align: center;
          cursor: default;
          .text-box {
            height: 0;
            p {
              width: 144px;
              height: 48px;
              line-height: 48px;
              color: #126ef8;
              border-radius: 24px;
              border: 1px solid #126ef8;
              margin: 56px auto;
            }
          }
        }
      }
    }
  }
}

// 弹框
.post-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 760px;
  border-radius: 10px;
  z-index: 1001;
  overflow: hidden;
  .company-inf {
    background: linear-gradient(219deg, #e2edff 0%, #f3f8ff 100%);
    height: 148px;
    padding: 28px 40px;
    p {
      strong {
        font-size: 20px;
        color: #252525;
        line-height: 24px;
        font-weight: 500;
      }
      &:nth-child(2),
      &:nth-child(3) {
        font-size: 16px;
        font-weight: 400;
        color: #126ef8;
      }
      &:nth-child(2) {
        margin: 10px 0;
        color: #848484;
      }
    }
  }
  .post-inf {
    background: #fff;
    padding: 24px 40px 28px;
    .inf-list {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        strong {
          font-size: 16px;
          color: #252525;
          font-weight: 400;
        }
      }
      ol {
        margin-top: 10px;
        li {
          font-size: 14px;
          font-weight: 400;
          color: #848484;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
