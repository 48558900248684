<template>
  <div class="caseCom">
    <!-- 5. 企业发展 -->
    <div class="develop-wrap">
      <div class="develop content">
        <!-- 标题 -->
        <div class="develop-title">
          <h1>企业发展历程</h1>
        </div>
        <!-- 内容 -->
        <div class="develop-content">
          <!-- 边框 -->
          <div class="border">
            <div :class="['line', 'line' + (lineIndex + 1)]"></div>
          </div>
          <!-- 导航 -->
          <div class="left-box">
            <ul v-for="(item, index) in tiemNav" :key="index">
              <li
                :class="[item.activeIndex == true ? 'active' : '']"
                @click="tabsFn(item, index)"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <!-- 右侧内容 -->
          <div class="right-box">
            <div
              :class="[
                'swiper-container',
                'swiper' + (index2 + 3),
                index2 == lineIndex ? 'active' : '',
              ]"
              v-for="(item2, index2) in rbArr"
              :key="index2"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <!-- 如果数据有值时 -->
                  <template v-if="item2.textArr.length > 0">
                    <div
                      class="item"
                      v-for="(item3, index3) in item2.textArr"
                      :key="index3"
                    >
                      <h1>{{ item3.title }}</h1>
                      <p v-for="(item4, index4) in item3.text" :key="index4">
                        <span class="round"></span>
                        <span class="text">{{ item4 }} </span>
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item">
                      <h1>凡是过往，皆为序章未来可期，美好正在发生。</h1>
                    </div>
                  </template>
                </div>
              </div>
              <!-- 滚动条 -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      lineIndex: 1, // 左侧竖线的激活下标
      // 右侧导航样式
      tiemNav: [
        { id: 1, title: "2023", activeIndex: false },
        { id: 2, title: "2022", activeIndex: true },
        { id: 3, title: "2021", activeIndex: false },
      ],
      // 企业发展历程
      rbArr: [
        {
          id: 1,
          title: "",
          textArr: [
            {
              title: "6月",
              text: [
                "被认定为国家科技型中小企业",
                "加入中国再生资源回收利用协会，成为一般会员单位",
                "受邀参加支付宝租赁行业大会",
              ],
            },
            {
              title: "5月",
              text: ["二奢事业部成立"],
            },
            {
              title: "4月",
              text: ["获得国家鼓励类企业称号"],
            },
          ],
        },
        {
          id: 2,
          textArr: [
            {
              title: "11月",
              text: [
                "多位前租赁行业内精英加入 升级来思租免押租赁企业文化V2.0",
                "商家租赁平台V1.0上线",
              ],
            },
            {
              title: "10月",
              text: ["来思租免押租赁平台化战略升级"],
            },
            {
              title: "9月",
              text: ["客户订单量突破2000单"],
            },
            {
              title: "5月",
              text: ["加入蚂蚁区块链"],
            },
            {
              title: "4月",
              text: ["与支付宝达成ISV合作服务商"],
            },
            {
              title: "3月",
              text: [
                "上线支付宝生活号 芝麻信用小程序 京东小白信用 各大应用市场上线支付宝生活号",
              ],
            },
          ],
        },
        {
          id: 3,
          textArr: [
            {
              title: "8月",
              text: ["支付宝小程序上线第一单自营租赁诞生"],
            },
          ],
        },
      ],
    };
  },
  methods: {
    // tabs切换
    tabsFn(it, i) {
      this.lineIndex = i;
      this.tiemNav.forEach((item, index) => {
        item.activeIndex = false;
        if (i == index) {
          item.activeIndex = true;
        }
      });
    },
  },
  mounted() {
    new Swiper(".swiper3", {
      // scrollbar: ".swiper-scrollbar",
      direction: "vertical",
      slidesPerView: "auto",
      mousewheelControl: true,
      freeMode: true,
    });
    new Swiper(".swiper4", {
      // scrollbar: ".swiper-scrollbar",
      direction: "vertical",
      slidesPerView: "auto",
      mousewheelControl: true,
      freeMode: true,
    });
    new Swiper(".swiper5", {
      // scrollbar: ".swiper-scrollbar",
      direction: "vertical",
      slidesPerView: "auto",
      mousewheelControl: true,
      freeMode: true,
    });
  },
};
</script>

<style scoped lang="less">
// 企业发展历程
.develop-wrap {
  background: #fff;
  width: 100%;
  .develop {
    padding: 56px 0 80px 0;
    .develop-title {
      text-align: center;
      h1 {
        font-size: 48px;
        font-weight: 500;
        color: #040c27;
        line-height: 48px;
        margin-bottom: 89px;
      }
    }
    .develop-content {
      display: flex;
      height: 100%;
      // 边框
      .border {
        width: 1px;
        height: 428.5px;
        background-color: #ededed;
        .line {
          width: 4px;
          height: 54px;
          background-color: #126ef8;
          margin-left: -2px;
          // margin-top: 35px;
          transition: 0.4s ease-in-out;
        }
        /* 分别设置各个红色小块的垂直位置 --- 这里如果后期要添加时间 需要把margin-top 累加一下 */
        .line1 {
          margin-top: 50px;
        }
        .line2 {
          margin-top: 178px;
        }
        .line3 {
          margin-top: 305px;
        }
      }
      // 左侧
      .left-box {
        width: 200px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        li {
          padding: 40px 0;
          font-size: 35.5px;
          font-weight: bold;
          color: #aaa;
          cursor: pointer;
          display: flex;
          align-items: center;
          /* 过渡效果 */
          transition: 0.4s ease-in-out;
        }
        // 激活样式
        .active {
          color: #333;
          font-size: 52px;
        }
      }
      // 右侧
      .right-box {
        flex: 1;
        width: 100%;
        // height: 952px;
        height: 800px;
        position: relative;
        background: #fafcfe;
        overflow: hidden;
        .swiper3,
        .swiper4,
        .swiper5 {
          width: 100%;
          height: 100%;
          position: absolute;
          top: -800px;
          opacity: 0;
          transition: 0.4s ease-in-out;

          .swiper-slide {
            width: 100%;
            // font-size: 18px;
            height: auto;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 64px 80px;
            cursor: pointer;
            .item {
              width: 100%;
              padding: 34px 36px 34px 36px;
              margin-bottom: 56px;
              background: #fff;
              &:last-child {
                margin-bottom: 0;
              }
              h1 {
                font-size: 30px;
                font-weight: 400;
                color: #2b384b;
                margin-bottom: 24px;
              }
              p {
                display: flex;
                align-items: flex-start;
                margin-bottom: 27px;
                &:last-child {
                  margin-bottom: 0;
                }
                .round {
                  margin-top: 8px;
                  flex-shrink: 0;
                  border-radius: 50%;
                  background-color: #126ef8;
                  width: 12px;
                  height: 12px;
                  display: block;
                }
                .text {
                  margin-left: 26px;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 32px;
                }
              }
            }
          }
        }
        // 激活样式
        .active {
          top: 0;
          opacity: 1;
        }
      }
    }
  }
}
</style>
