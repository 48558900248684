<template>
  <div class="productLsz">
    <!-- 头部导航 -->
    <navComTwo></navComTwo>
    <!-- banner图部分 -->
    <div class="bannerCom">
      <div class="banner-box">
        <!-- 背景图 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="banner">
                <!-- 图片 -->
                <img
                  class="box-img"
                  src="../assets/img/productLsz/product-banner.png"
                  alt=""
                />
                <!-- 标题内容 -->
                <div class="banner-box content">
                  <div class="text">
                    <p class="title">一站式全品类信用免押租赁平台</p>
                    <p>
                      来思租免押租赁支付宝线上租赁小程序，助力租赁商家实现线下
                      门店线上化运营，并提供相
                      应的运营、风控等服务，赋能租赁服务商在线化、工具化、数字化和智能化。
                    </p>
                  </div>
                  <div class="code">
                    <div class="code-img">
                      <img
                        src="../assets/img/lsz_code.jpg"
                        style="width: 100%; height: 100%; display: block"
                        alt=""
                      />
                    </div>
                    <p>扫码了解小程序</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1. 核心优势 -->
    <div class="advantage-wrap">
      <div class="advantage content">
        <!-- 标题 -->
        <div class="advantage-title">
          <h1>核心优势</h1>
        </div>
        <!-- 列表 -->
        <div class="advantage-list">
          <div class="list-item-l">
            <div class="list-item one-item-l">
              <div class="item-l">
                <img src="../assets//img//productLsz/adv-min-img.png" alt="" />
              </div>
              <div class="item-r">
                <h5>获客能力</h5>
                <p>
                  通过支付宝、京东、微信等其他电商平台合作线上引流到线下，和线下门店线上化运来免押租赁实现了线上、线下相互导流，确保更广泛的流量来源。
                </p>
              </div>
            </div>
            <div class="list-item two-item-l">
              <div class="item-l">
                <img src="../assets//img//productLsz/adv-min-img2.png" alt="" />
              </div>
              <div class="item-r">
                <h5>区块链</h5>
                <p>
                  通过运用阿里区块链技术，打通业务渠道资金壁垒，确保资产质量，为公司平台化发展
                  奠定了基石。
                </p>
              </div>
            </div>
          </div>
          <div class="list-item-r">
            <div class="list-item three-item-r">
              <div class="item-r">
                <h5 style="text-align: right">风控能力</h5>
                <p>
                  来思租免押租赁充分运用芝麻信用，第三方数据平台等多维度评估承租人的信用状况，通过自主研发的大数据风控模型，强有力地保证了租赁资产的安全性。
                </p>
              </div>
              <div class="item-l">
                <img src="../assets//img//productLsz/adv-min-img3.png" alt="" />
              </div>
            </div>
            <div class="list-item four-item-r">
              <div class="item-r">
                <h5 style="text-align: right">供应链</h5>
                <p>
                  来思租免押租赁拥有优质且稳定的供应链资源，
                  以及自有租赁平台，对租赁市场的熟悉度，远超同业平台公司，能及时对租赁回收的资产进行高效处置。
                </p>
              </div>
              <div class="item-l">
                <img src="../assets//img//productLsz/adv-min-img4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2. 业务闭环 -->
    <div class="closedLoop-wrap">
      <div class="closedLoop content">
        <!-- 标题 -->
        <div class="closedLoop-title">
          <h1>全面构建租赁业务闭环</h1>
          <p>
            公私域引流、线上店铺、店铺营销、免押金下单、租中租后管理、资产保障、全面构建租赁业务闭环
          </p>
        </div>
        <!-- 列表 -->
        <div class="closedLoop-list">
          <!-- 卡片 -->
          <div class="card">
            <div class="card-list card-list1">
              <img src="../assets/img/productLsz/closed-max-hk.png" alt="" />
              <h5>获客</h5>
              <p>多维平台公域流量，多平台私域赋能方案；助力商家业务增长</p>
            </div>
            <div class="card-list card-list2">
              <img src="../assets/img/productLsz/closed-min-my.png" alt="" />
              <h5>免押金</h5>
              <p>多维且全面的信用数据，经过时间验证的免押金风控方案</p>
            </div>
            <div class="card-list card-list3">
              <img src="../assets/img/productLsz/closed-max-dk.png" alt="" />
              <h5>租金代扣</h5>
              <p>智能的通知提醒和资金代扣策略确保租金支付及时稳定</p>
            </div>
            <div class="card-list card-list4">
              <img src="../assets/img/productLsz/closed-min-zwl.png" alt="" />
              <h5>租物链</h5>
              <p>包含订单，电子合同，物流及签收存证等关键租物数据</p>
            </div>
            <div class="card-list card-list5">
              <img src="../assets/img/productLsz/closed-max-zc.png" alt="" />
              <h5>司法仲裁</h5>
              <p>完整的仲裁案件提交及管理流程，简单快捷</p>
            </div>
            <div class="card-list card-list6">
              <img src="../assets/img/productLsz/closed-min-fx.png" alt="" />
              <h5>数据分析</h5>
              <p>完美精准的数据处理满足精细化业务管理需求</p>
            </div>
          </div>
          <!-- 折线 -->
          <div class="fold-line">
            <div class="line-img">
              <img src="../assets//img/productLsz/fold-line.png" alt="" />
            </div>
            <div class="round-dot dot-color1">
              <div class="dot"></div>
            </div>
            <div class="round-dot dot-color2">
              <div class="dot"></div>
            </div>
            <div class="round-dot dot-color3">
              <div class="dot"></div>
            </div>
            <div class="round-dot dot-color4">
              <div class="dot"></div>
            </div>
            <div class="round-dot dot-color5">
              <div class="dot"></div>
            </div>
            <div class="round-dot dot-color6">
              <div class="dot"></div>
            </div>
          </div>
          <!-- 标语 -->
          <div class="slogan">
            <div class="slogan-item">租用前</div>
            <div class="slogan-item">租用中</div>
            <div class="slogan-item">租用后</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3. 商户系统能力展示 -->
    <div class="capacityshow-wrap">
      <div class="capacityshow content">
        <!-- 左侧图片展示 -->
        <div class="capacityshow-l">
          <img src="../assets//img/productLsz/show-img.png" alt="" />
        </div>
        <!-- 右侧内容展示 -->
        <div class="capacityshow-r">
          <h1>商户系统能力展示</h1>
          <p>
            来思租免押租赁为每个租赁商线下交易提供高效率低门槛的
            <span>“扫码工具”</span>。 用户无须下 载, 扫码即用。
          </p>
          <div class="show-list">
            <div class="list-l">
              <img src="../assets/img/productLsz/ios.png" alt="" />
              <div class="list-item">
                <h3>移动端</h3>
                <ul>
                  <li><span>便捷处理订单</span></li>
                  <li><span>查看订单</span></li>
                  <li><span>查看收益</span></li>
                  <li><span>调整上下架</span></li>
                </ul>
              </div>
            </div>
            <div class="list-r">
              <img src="../assets/img/productLsz/pc.png" alt="" />
              <div class="list-item">
                <h3>pc端</h3>
                <ul>
                  <li><span>店铺管理</span></li>
                  <li><span>商品上下架</span></li>
                  <li><span>订单处理</span></li>
                  <li><span>在线自助提现</span></li>
                  <li><span>运营推广</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4. 运营方向 -->
    <div class="operate-wrap">
      <div class="operate content">
        <!-- 标题 -->
        <div class="operate-title">
          <h1>运营方向</h1>
        </div>
        <!-- 列表 -->
        <div class="operate-list">
          <div
            class="list-item"
            v-for="(item, index) in operateList"
            :key="index"
          >
            <div class="item-title">
              <img :src="item.imgSrc" alt="" />
              <span>{{ item.title }}</span>
            </div>
            <ul class="item-content">
              <li v-for="(item2, index2) in item.text" :key="index2">
                <img
                  src="../assets/img/productLsz/success.png"
                  alt=""
                  style="width: 16px; height: 16px"
                />
                <span> {{ item2 }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 5. 入驻流程 -->
    <div class="settlein-wrap">
      <div class="settlein content">
        <!-- 标题 -->
        <div class="settlein-title">
          <h1>入驻流程</h1>
        </div>
        <!-- 列表 -->
        <div class="settlein-list">
          <div class="list-top">
            <div
              :class="['list-item', index == 0 ? 'list-item-bg' : '']"
              v-for="(item, index) in settleinTop"
              :key="index"
            >
              <div class="title">
                <div class="num">{{ item.num }}</div>
                <div class="text">{{ item.title }}</div>
              </div>
              <ul class="contents" v-if="item.listArr.length > 0">
                <li v-for="(item2, index2) in item.listArr" :key="index2">
                  {{ item2 }}
                </li>
              </ul>
            </div>
          </div>
          <div class="list-bottom">
            <div
              :class="['list-item', index == 0 ? 'list-item-bg' : '']"
              v-for="(item, index) in settleinBottom"
              :key="index"
            >
              <div class="title">
                <div class="num">{{ item.num }}</div>
                <div class="text">{{ item.title }}</div>
              </div>
              <ul class="contents" v-if="item.listArr.length > 0">
                <li v-for="(item2, index2) in item.listArr" :key="index2">
                  {{ item2 }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <!-- <contact-com></contact-com> -->
    <!-- 底部组件部分 -->
    <footerCom></footerCom>
  </div>
</template>

<script>
import navComTwo from "@/components/navComTwo.vue";
// import contactCom from "@/components/contactCom.vue";
import footerCom from "@/components/footerCom.vue";
export default {
  data() {
    return {
      // 核心优势数据
      advantageArr: [
        {
          id: 1,
          imgSrc: require("../assets/img/productLsz/adv-min-img.png"),
          title: "获客能力",
          text: "通过支付宝、京东、微信等其他电商平台合作 线上引流到线下，和线下门店线上化运来免押租赁实现了线上、线下相互导流，确保更广泛的流量来源。",
        },
        {
          id: 2,
          imgSrc: require("../assets/img/productLsz/adv-min-img.png"),
          title: "分控能力",
          text: "来思租免押租赁充分运用芝麻信用，第三方数据平台等多维度评估承租人的信用状况，通过自主研发的大数据风控模型，强有力地保证了租赁资产的安全性。",
        },
        {
          id: 3,
          imgSrc: require("../assets/img/productLsz/adv-min-img.png"),
          title: "区块链",
          text: "来思租免押租赁拥有优质且稳定的供应链资源， 以及自有租赁平台，对租赁市场的熟悉度，远超同业平台公司，能及时对租赁回收的资产进行高效处置。",
        },
        {
          id: 4,
          imgSrc: require("../assets/img/productLsz/adv-min-img.png"),
          title: "供应链",
          text: "通过支付宝、京东、微信等其他电商平台合作 线上引流到线下，和线下门店线上化运来免押租赁实现了线上、线下相互导流，确保更广泛的流量来源。",
        },
      ],
      // 运营方向数据
      operateList: [
        {
          id: 1,
          imgSrc: require("../assets/img/productLsz/direction-img.png"),
          title: "流量运营",
          text: ["免费流量-搜索、服务直达等", "付费流量-灯火投放等"],
        },
        {
          id: 2,
          imgSrc: require("../assets/img/productLsz/direction-img2.png"),
          title: "生活号+运营",
          text: [
            "更有效的信息发布",
            "更及时动态更新",
            "更多的流量获取",
            "更好的用户留存及转化",
          ],
        },
        {
          id: 3,
          imgSrc: require("../assets/img/productLsz/direction-img3.png"),
          title: "运营辅助",
          text: ["天天抽奖", "租物频道", "有点东西", "服务关键词"],
        },
        {
          id: 4,
          imgSrc: require("../assets/img/productLsz/direction-img4.png"),
          title: "协助提升",
          text: ["开通繁星计划", "提升SCI分数", "租押分离", "接入灯火"],
        },
      ],
      // 入驻流程-上部分
      settleinTop: [
        {
          id: 1,
          num: "01",
          title: "入驻洽谈",
          listArr: ["确定合作意向"],
        },
        {
          id: 2,
          num: "02",
          title: "签订协议",
          listArr: ["确定合作内容", "签订合作协议"],
        },
        {
          id: 3,
          num: "03",
          title: "商家入驻",
          listArr: ["商户注册", "提交入驻资料", "服务人员审核", "通知商户结果"],
        },
        {
          id: 4,
          num: "04",
          title: "商家培训",
          listArr: ["商户系统培训", "产品知识培训", "风控知识培训"],
        },
      ],
      // 入驻流程-下部分
      settleinBottom: [
        {
          id: 1,
          num: "08",
          title: "结算",
          listArr: [],
        },
        {
          id: 2,
          num: "07",
          title: "开展业务",
          listArr: ["租赁业务及政策", "签订合作协议"],
        },
        {
          id: 3,
          num: "06",
          title: "增值服务",
          listArr: [],
        },
        {
          id: 4,
          num: "05",
          title: "商户信息完善",
          listArr: ["完善商户信息", "产品上架", "价格调整", "旺铺设置"],
        },
      ],
    };
  },
  components: {
    navComTwo,
    // contactCom,
    footerCom,
  },
};
</script>

<style scoped lang="less">
.productLsz {
  // 摩咖banner图
  .bannerCom {
    position: relative;
    width: 100%;
    // 1. banner轮播部分
    .swiper-container {
      width: 100%;
      // height: calc(100vw * 530 / 1920);
      height: 530px;
      // min-height: 530px;
      // min-height: 430px;
      .swiper-wrapper {
        width: 100%;
        // height: calc(100vw * 530 / 1920);
        height: 530px;
        // min-height: 530px;
        // min-height: 430px;

        .swiper-slide {
          width: 100%;
          // height: calc(100vw * 530 / 1920);
          height: 530px;
          // min-height: 530px;
          // min-height: 430px;

          .banner {
            position: relative;
            width: 100%;
            height: 530px;
            // height: calc(100vw * 530 / 1920);
            // min-height: 530px;
            // min-height: 360px;
            // background-image: url("../assets/img/productLsz/product-banner.png");
            // background-size: 100% 100%;
            // background-repeat: no-repeat;
            .box-img {
              width: 1920px;
              position: absolute;
              left: 50%;
              margin-left: -960px;
            }
            .banner-box {
              position: absolute;
              bottom: 8.301%;
              left: 50%;
              transform: translateX(-50%);
              // 内容部分
              .text {
                margin-bottom: 5.2%;
                .title {
                  // width: 486px;
                  width: 756px;
                  line-height: 63px;
                  letter-spacing: 3px; // 设置对象中的文字之间的间隔
                  font-size: 50px;
                  font-weight: 500;
                  font-family: Noto Sans S Chinese-Medium, Noto Sans S Chinese;
                  color: #ffffff;
                }
                p {
                  // width: 486px;
                  width: 612px;
                  font-size: 16px;
                  line-height: 28px;
                  letter-spacing: 0px;
                  color: #dbdbdb;
                  font-weight: 400;
                  margin-top: 10px;
                }
              }
              .code {
                width: 112px;
                text-align: center;
                .code-img {
                  margin: 0 auto;
                  background-color: #d9d9d9;
                  width: 104px;
                  height: 104px;
                }
                p {
                  font-size: 14px;
                  color: #ffffff;
                  font-weight: 500;
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  // 核心优势
  .advantage-wrap {
    padding-top: 87px;
    padding-bottom: 80px;
    // 标题
    .advantage-title {
      h1 {
        font-size: 48px;
        font-weight: 500;
        color: #040c27;
        line-height: 48px;
        text-align: center;
        margin-bottom: 89px;
      }
    }
    // 内容
    .advantage-list {
      width: 100%;
      // height: 446px;
      height: 538px;
      background: url("../assets/img/productLsz/advantage-img.png") no-repeat
        center;
      background-size: 598px 446px;
      display: flex;
      justify-content: space-between;
      .list-item-l,
      .list-item-r {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .list-item {
          display: flex;
          align-items: center;
          .item-l {
            width: 96px;
            height: 96px;
            margin-right: 20px;
            margin-left: 20px;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .item-r {
            width: 376px;
            display: flex;
            flex-direction: column;
            h5 {
              font-size: 24px;
              color: #2b384b;
              font-weight: 400;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              color: #848484;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  // 业务闭环
  .closedLoop-wrap {
    background: #fafcfe;
    padding-top: 97px;
    padding-bottom: 80px;
    // 标题
    .closedLoop-title {
      text-align: center;
      h1 {
        font-size: 48px;
        font-weight: 500;
        color: #040c27;
        line-height: 48px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        color: #848484;
        margin-top: 22px;
        margin-bottom: 89px;
      }
    }
    // 内容
    .closedLoop-list {
      // 卡片
      .card {
        position: relative;
        // background: orange;
        height: 386px;
        width: 100%;
        .card-list {
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 32px 20px 42px 18px;
          // border: 1px solid red;
          background: pink;
          // width: 200px;
          height: 306px;
          width: 14.28%;
          // height: 21.85%;
          background: url("../assets/img/productLsz/Union.png") no-repeat center;
          background-size: 100% 100%;
          img {
            width: 80px;
            height: 80px;
            display: block;
          }
          h5 {
            font-size: 20px;
            color: #000000;
            font-weight: 400;
            margin-top: 22px;
            margin-bottom: 12px;
          }
          p {
            display: inline-block;
            text-align: justify;
            font-weight: 400;
            color: #848484;
            font-size: 16px;
            line-height: 28px;
          }
        }
        // 控制折现图==》卡片定位
        .card-list1 {
          // left: 6.78%;
          left: 0%;
        }
        .card-list2 {
          left: 16.79%;
          top: 65px;
          background: url("../assets/img/productLsz/Union.png") no-repeat center;
        }
        .card-list3 {
          left: 33.93%;
          background: url("../assets/img/productLsz/Union(1).png") no-repeat
            center;
        }
        .card-list4 {
          left: 51.07%;
          top: 65px;
          background: url("../assets/img/productLsz/Union(1).png") no-repeat
            center;
        }
        .card-list5 {
          left: 68.22%;
          background: url("../assets/img/productLsz/Union(2).png") no-repeat
            center;
        }
        .card-list6 {
          left: 85.36%;
          top: 65px;
          background: url("../assets/img/productLsz/Union(2).png") no-repeat
            center;
        }
      }
      // 折线
      .fold-line {
        margin-bottom: 67px;
        .line-img {
          width: 100%;
          height: 82px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        position: relative;
        .round-dot {
          position: absolute;
          width: 18px;
          height: 18px;
          line-height: 14px;
          border: 1px solid #729dfe;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot {
            width: 14px;
            height: 14px;
            line-height: 14px;
            border-radius: 50%;
            background: #70c2fe;
          }
        }

        .dot-color1 {
          top: -9px;
          // left: 95px;
          left: 6.78%;
          border: 1px solid #729dfe;
          .dot {
            background: #70c2fe;
          }
        }
        // 其它圆点样式
        .dot-color2 {
          top: 73px;
          // left: 330px; // 版型为1400时
          left: 23.57%;
          border: 1px solid #70c2fe;
          .dot {
            background: #70c2fe;
          }
        }

        .dot-color3 {
          top: -9px;
          // left: 570px;
          left: 40.71%;
          border: 1px solid #a5f199;
          .dot {
            background: #a4f198;
          }
        }
        .dot-color4 {
          top: 73px;
          // left: 810px;
          left: 57.85%;
          border: 1px solid #a4f198;
          .dot {
            background: #a4f198;
          }
        }
        .dot-color5 {
          top: -9px;
          // left: 1050px;
          left: 75%;
          border: 1px solid #729dfe;
          .dot {
            background: #729dfe;
          }
        }
        .dot-color6 {
          top: 73px;
          // left: 1290px;
          left: 92.14%;
          background: #729dfe;
          .dot {
            background: #70c2fe;
          }
        }
      }
      // 标语
      .slogan {
        display: flex;
        justify-content: space-around;
        .slogan-item {
          width: 176px;
          height: 56px;
          line-height: 56px;
          text-align: center;
          background: #70c2fe;
          border-radius: 4px;
          font-size: 24px;
          color: #ffffff;
          font-weight: 400;
          &:nth-child(2) {
            background: #a4f198;
          }
          &:nth-child(3) {
            background: #729dfe;
          }
        }
      }
    }
  }
  // 商户系统展示
  .capacityshow-wrap {
    .capacityshow {
      display: flex;
      justify-content: space-between;

      // 左侧图片
      .capacityshow-l {
        width: 48.57%;
        height: 48.57%;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      // 右侧内容
      .capacityshow-r {
        padding-top: 56px;
        width: 48.57%;
        height: 48.57%;
        height: 100%;
        h1 {
          text-align: center;
          font-size: 38px;
          color: #040c27;
          font-weight: 500;
        }
        p {
          width: 530px;
          line-height: 32px;
          font-size: 16px;
          color: #848484;
          font-weight: 400;
          margin: 24px auto 48px;
          text-align: justify;
          span {
            color: #4187ff;
            font-size: 16px;
          }
        }
        .show-list {
          display: flex;
          justify-content: space-between;
          .list-l,
          .list-r {
            width: 48.75%;
            background: #fafcff;
            display: flex;
            img {
              width: 113px;
              height: 113px;
              display: block;
            }
            .list-item {
              flex: auto;
              h3 {
                padding-top: 32px;
                padding-bottom: 56px;
              }
              ul {
                li {
                  padding-bottom: 32px;

                  color: #126ef8;
                  font-size: 14px;
                  line-height: 16px;
                  list-style-type: disc;
                  span {
                    color: #848484;
                  }
                  // &::before {
                  //   content: "";
                  //   width: 4px;
                  //   height: 4px;
                  //   line-height: 4px;
                  //   display: inline-block;
                  //   // margin-bottom: 2px;
                  //   // margin-right: 10px;
                  //   border-radius: 50%;
                  //   background: #126ef8;
                  // }
                }
              }
            }
          }
          .list-r {
          }
        }
      }
    }
  }
  // 运营方向
  .operate-wrap {
    background: #fafcfe;
    .operate {
      padding-top: 79px;
      padding-bottom: 80px;
      .operate-title {
        text-align: center;
        h1 {
          font-size: 48px;
          line-height: 48px;
          color: #040c27;
          font-weight: 500;
          margin-bottom: 89px;
        }
      }
      .operate-list {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .list-item {
          background: #fff;
          width: 288px;
          height: 336px;
          border-radius: 8px;
          overflow: hidden;

          .item-title {
            background: linear-gradient(358deg, #126ef8 0%, #76c0fa 100%);
            height: 96px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              display: block;
            }
            span {
              margin-left: 24px;
              font-size: 20px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .item-content {
            padding: 26px 0;
            margin-left: 30px;
            li {
              padding: 12px 0;
              span {
                margin-left: 12px;
                font-size: 14px;
                color: #848484;
                line-height: 16px;
                font-weight: 400;
              }
            }
          }
          &:nth-child(2) {
            .item-content {
              margin-left: 56px;
            }
          }
          &:nth-child(3),
          &:nth-child(4) {
            .item-content {
              margin-left: 88px;
            }
          }
        }
      }
    }
  }
  // 入驻流程
  .settlein-wrap {
    .settlein {
      padding-top: 79px;
      padding-bottom: 380px;
      .settlein-title {
        text-align: center;
        h1 {
          font-size: 48px;
          line-height: 48px;
          color: #040c27;
          font-weight: 500;
          margin-bottom: 89px;
        }
      }
      .settlein-list {
        border: 1px dashed #0c89f6;
        border-radius: 18px;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        height: 399px;
        // 顶部内容
        .list-top,
        .list-bottom {
          display: flex;
          justify-content: space-between;
          margin-top: -28px;
          // 控制渐变色遮盖虚线
          .list-item-bg {
            background: linear-gradient(90deg, #fff, #fff, rgba(0, 0, 0, 0));
          }
          .list-item {
            width: 320px;
            // height: 325px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
              width: 208px;
              height: 56px;
              background: #126ef8;
              border-radius: 28px;
              display: flex;
              align-items: center;
              .num {
                width: 56px;
                height: 56px;
                line-height: 48px;
                text-align: center;
                background: #fff;
                border-radius: 50%;
                font-size: 24px;
                font-weight: bold;
                border: 4px solid #126ef8;
              }
              .text {
                flex-grow: 1;
                text-align: center;
                margin-left: -28px;
                font-size: 20px;
                color: #fafcfe;
                font-weight: 400;
              }
            }
            .contents {
              width: 208px;
              background: #f4f9ff;
              padding: 20px;
              margin-top: 20px;
              li {
                background: #fff;
                text-align: center;
                margin: 10px;
                font-weight: 400;
                color: #252525;
                font-size: 16px;
                line-height: 48px;
                height: 48px;
              }
            }
          }
        }
        // 底部内容
        .list-bottom {
          margin-top: 40px;
        }
      }
    }
  }
}

// 核心优势==》控制边距部分
.one-item-l {
  margin-top: 72px;
}
.two-item-l {
  margin-bottom: 29px;
  margin-left: 38px;
  // margin-left: 64px;
}
// .three-item-r {
// }
.four-item-r {
  // margin-left: -67px;
  margin-left: -43px;
}
</style>
