<template>
  <div class="footerCom">
    <div class="footer-wrap content">
      <!-- 导航名字 -->
      <div class="b_nav">
        <div class="b_nav_l">
          <dl class="contact">
            <dt>联系我们</dt>
            <dd>
              <img src="../assets/img/indexView/mobile.png" />
              <span>17033022367</span>
            </dd>
            <dd>
              <img src="../assets/img/indexView/mailbox-icon.png" />
              <span>nice_technology@163.com</span>
            </dd>
            <dd style="width: 301px">
              <img src="../assets/img/indexView/address.png" />
              <span>安徽省合肥市蜀山区黄山路451号世界之窗 A2座1116 </span>
            </dd>
          </dl>
          <dl style="margin-left: 150px" class="browse">
            <dt>快速浏览</dt>
            <dd @click="toPageFn('/indexView')" style="cursor: pointer">
              <span>首页</span>
            </dd>
            <dd>
              <dl>
                <dt style="font-size: 14px; font-weight: 400">产品中心</dt>
                <dd
                  style="margin-bottom: 14px; cursor: pointer"
                  @click="toPageFn('/productLsz')"
                >
                  <span
                    style="font-size: 14px; color: rgba(255, 255, 255, 0.5)"
                  >
                    来思租
                  </span>
                </dd>
                <dd
                  style="margin-bottom: 14px; cursor: pointer"
                  @click="toPageFn('/productMk')"
                >
                  <span style="font-size: 14px; color: rgba(255, 255, 255, 0.5)"
                    >摩咖</span
                  >
                </dd>
              </dl>
            </dd>
            <dd @click="toPageFn('/cooperate')" style="cursor: pointer">
              <span>合作案例</span>
            </dd>
            <dd @click="toPageFn('/aboutUs')" style="cursor: pointer">
              <span>关于我们</span>
            </dd>
          </dl>
        </div>
        <div class="b_nav_r">
          <dl>
            <dt>关于我们</dt>
            <dd>
              <div class="lxz-code">
                <div
                  class="lxz-img"
                  style="width: 146px; height: 146px; background: #d9d9d9"
                >
                  <img
                    src="../assets/img/lsz_code.jpg"
                    style="width: 100%; height: 100%; display: block"
                    alt=""
                  />
                </div>
                <p>来思租支付宝小程序</p>
              </div>
              <div class="mk-code">
                <div
                  class="mk-img"
                  style="width: 146px; height: 146px; background: #d9d9d9"
                >
                  <img
                    src="../assets/img/mk_code.jpg"
                    style="width: 100%; height: 100%; display: block"
                    alt=""
                  />
                </div>
                <p>摩咖支付宝小程序</p>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- 公司名称 -->
      <div class="btmbg">
        <div class="btm">
          <p>合肥来思信息科技有限责任公司</p>
          <p>Copyright@2023 皖ICP备2022010761号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navCom",
  data() {
    return {};
  },
  methods: {
    toPageFn(url) {
      // this.$nextTick(() => {
      //   document.body.scrollTop = 0;
      // });
      this.$router.push(url);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.footerCom {
  background: #19243a;
  .footer-wrap {
    padding: 64px 0 66px 0;
    // 导航
    .b_nav {
      display: flex;
      justify-content: space-between;

      .b_nav_l {
        flex: 2;
        display: flex;
        .contact {
          dd {
            display: flex;
            align-items: flex-start;
            img {
              width: 22px;
              height: 22px;
              display: block;
              margin-right: 9px;
            }
          }
        }
        .browse {
        }
      }
      .b_nav_r {
        flex: 1;
        display: flex;
        dd {
          display: flex;
          .lxz-code {
            margin-right: 40px;
          }
          .mk-code,
          .lxz-code {
            p {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              margin-top: 18px;
            }
          }
        }
      }
      dl {
        dt {
          font-size: 18px;
          font-weight: 400;
          padding-bottom: 24px;
          color: #fff;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        }
        dd {
          margin-bottom: 24px;
          span {
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
    }
    // 底部公司名
    .btmbg {
      margin-top: 75px;
      p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 25px;
        text-align: center;
      }
    }
  }
}
</style>
