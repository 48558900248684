import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入全局样式
import "./assets/css/common.css";

// 引入swiper
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";

// 引入element-ui的样式
import "element-ui/lib/theme-chalk/index.css";
import "./plugins/element.js";

Vue.config.productionTip = false;

// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
